import create, { SetState, GetState, Mutate, StoreApi } from "zustand";
import { persist, devtools } from "zustand/middleware";

import { SelectedCalendar } from "@util/types";
import { User, Order, Customer, AdCopyElements, AdCopy } from "./types";
import { Maybe, SanitySizeBlock } from "@graphql-types";

interface State {
  rehydrated: boolean;
  drawerVisible: boolean;
  categoryFilters: string[];
  currentSearchValue: string;
  ageRestrictionActive: boolean;
  ageRestrictionPopupVisible: boolean;
  drawerForm: "enquiry" | "login";
  selectedCalendar: SelectedCalendar;
  user?: User;
  order?: Order;
  selectedCustomer?: Customer;
  online: boolean;
  createCustomerVisible: boolean;
  loggedIn: boolean;
  adCopy?: AdCopy;
  showTips?: boolean;

  setLoggedIn: (loggedIn: boolean) => void;
  setSelectedCustomer: (selectedCustomer: Customer | undefined) => void;
  openDrawer: () => void;
  closeDrawer: () => void;
  disableAgeRestriction: () => void;
  openAgeRestrictionPopup: () => void;
  closeAgeRestrictionPopup: () => void;
  openLoginDrawer: () => void;
  setUser: (user: User) => void;
  setOrder: (order: Order | undefined) => void;
  setAdCopy: (adCopy: AdCopy | undefined) => void;
  setAdCopySignature: (signature: string) => void;
  getAdCopyElements: () => AdCopyElements | undefined;
  getProductAvertSize: () => Maybe<SanitySizeBlock> | undefined;
  clearSession: () => void;
  setRehydrated: () => void;
  setOnline: (online: boolean) => void;
  setCreateCustomerVisible: (visible: boolean) => void;
  getOrderZipName: () => string | undefined;
  setShowTips: (showTips: boolean) => void;

  //Template hooks
  usingTemplate: boolean;
  templateAvailable: {
    returned: boolean;
    available: boolean;
    templates: any;
    pickerSelected: boolean;
  };
  templateFieldset: any;
  setUsingTemplate: (usingTemplate: boolean) => void;
  setTemplateAvailable: (templateAvailable: any) => void;
  setTemplateFieldset: (templateFieldset: any) => void;
}

const defaultSelectedCalendar = {
  productId: "",
  productName: "",
};

const defaultState = {
  rehydrated: false,
  categoryFilters: [],
  currentSearchValue: "",
  ageRestrictionActive: true,
  ageRestrictionPopupVisible: false,
  drawerVisible: false,
  selectedCalendar: defaultSelectedCalendar,
  online: false,
  createCustomerVisible: false,
  loggedIn: false,
  usingTemplate: false,
  templateAvailable: {
    returned: false,
    available: false,
    templates: null,
    pickerSelected: false,
  },
  templateFieldset: null,
};

const store = persist<
  State,
  SetState<State>,
  GetState<State>,
  Mutate<StoreApi<State>, [["zustand/persist", Partial<State>]]>
>(
  (set, get) => ({
    ...defaultState,
    drawerForm: "enquiry",
    openLoginDrawer: () => set({ drawerVisible: true, drawerForm: "login" }),
    openDrawer: () => set({ drawerVisible: true, drawerForm: "enquiry" }),
    closeDrawer: () => set({ drawerVisible: false, drawerForm: "enquiry" }),
    disableAgeRestriction: () => set({ ageRestrictionActive: false }),
    openAgeRestrictionPopup: () => set({ ageRestrictionPopupVisible: true }),
    closeAgeRestrictionPopup: () => set({ ageRestrictionPopupVisible: false }),
    getAdCopyElements: () => get().adCopy?.adCopyElements,
    getProductAvertSize: () => get().order?.product?.advertSize,
    setAdCopy: adCopy => set({ adCopy }),
    setAdCopySignature: signature => set({ adCopy: { ...get().adCopy, signature } }),
    clearSession: () =>
      set({
        user: undefined,
        selectedCustomer: undefined,
        order: undefined,
        loggedIn: false,
        templateAvailable: {
          returned: false,
          available: false,
          templates: null,
          pickerSelected: false,
        },
        templateFieldset: null,
      }),
    setRehydrated: () => set({ rehydrated: true }),
    setOnline: online => set({ online }),
    setCreateCustomerVisible: createCustomerVisible => set({ createCustomerVisible }),
    setSelectedCustomer: selectedCustomer => set({ selectedCustomer }),
    setUser: user => set({ user, loggedIn: true }),
    setOrder: order => set({ order }),
    setLoggedIn: loggedIn => set({ loggedIn }),
    getOrderZipName: () => get().order?.displayOrderID ?? get().order?.id,
    setUsingTemplate: (usingTemplate: boolean) => set({ usingTemplate }),
    setTemplateAvailable: (templateAvailable: any) => set({ templateAvailable }),
    setTemplateFieldset: (templateFieldset: any) => set({ templateFieldset }),
    setShowTips: (showTips: boolean) => set({ showTips }),
  }),
  {
    name: "easy2c-store", // unique name
    getStorage: () => localStorage,
    // omit calendar from persisted storage
    partialize: state =>
      Object.fromEntries(
        Object.entries(state).filter(
          ([key]) => !["adCopy", "rehydrated", "usingTemplate", "templateAvailable"].includes(key),
        ),
      ),
  },
);

export const useStore = create(
  process.env.GATSBY_NODE_ENV !== "production" ? devtools(store) : store,
);
