import React, { useRef, useState } from "react";

import { BlocksContent, Image } from "@global";
import {
  Maybe,
  SanityBlockContent,
  SanityCollection,
  SanityImageWithMeta,
  SanityPackaging,
} from "@graphql-types";
import { Container } from "@util/standard";

import { formatInternalLink, isBrowser } from "@util/helper";
import { useCheckScreenWidth, useEventListener } from "@util/hooks";
import { ColumnWrapper, ParallaxWrapper, StyledLink, TextWrapper } from "./global.styles";
import { isSanityCollection, isSanityPackaging } from "@util/types";

interface Props {
  data: Maybe<SanityCollection | SanityPackaging>[];
}

interface ColumnProps {
  index: number;
  image: Maybe<SanityImageWithMeta> | undefined;
  description: Maybe<SanityBlockContent> | undefined;
  collection?: SanityCollection;
  isLast?: boolean;
  key: Maybe<string> | undefined;
  scrollValue: number;
}

const ParallaxColumn = ({
  index,
  image,
  description,
  isLast,
  key,
  collection,
  scrollValue,
}: ColumnProps) => {
  if (description == null) return null;

  const defineColor = index % 3 === 0 ? "mint" : (index + 1) % 2 === 0 ? "cream" : "lightBlue";

  return (
    <ColumnWrapper
      index={index}
      backgroundColor={defineColor}
      isLast={isLast}
      scrollValue={scrollValue}
      key={key}
    >
      <Container width="100%" height="130px">
        {image && <Image data={image} />}
      </Container>
      <TextWrapper justifyContent="center">
        {description && <BlocksContent data={description} />}
      </TextWrapper>
      {collection && (
        <StyledLink to={`${formatInternalLink(collection)}${collection?.slug?.current}`}>
          View {collection.title}
        </StyledLink>
      )}
    </ColumnWrapper>
  );
};

function ParallaxColumns({ data }: Props) {
  if (data == null) return null;
  const [scrollValue, setScrollValue] = useState(0);
  const [globalScrollY, setGlobalScrollY] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();

  const scrollColumns = () => {
    const isDesktop = !isMobileWidth && !isTabletWidth;

    if (isBrowser() && containerRef.current && isDesktop) {
      setGlobalScrollY(window.scrollY);

      const offsetTop = containerRef.current?.offsetTop;
      const height = containerRef.current.offsetHeight;
      const viewPortHalf = window.innerHeight / 2;
      if (
        globalScrollY > window.scrollY &&
        globalScrollY <= offsetTop + height + viewPortHalf &&
        globalScrollY <= offsetTop + height + viewPortHalf
      ) {
        setScrollValue(prev => (prev > 0 ? prev - 2 : prev));
      }
      if (
        globalScrollY < window.scrollY &&
        globalScrollY >= offsetTop + viewPortHalf &&
        globalScrollY <= offsetTop + height + viewPortHalf
      ) {
        setScrollValue(prev => (prev < 100 ? prev + 2 : prev));
      }
      return;
    }
    return;
  };

  useEventListener("scroll", scrollColumns);

  return (
    <ParallaxWrapper ref={containerRef}>
      {data.map((item, index) => {
        if (item == null) return null;
        const isLast = index + 1 === data?.length;

        if (isSanityCollection(item)) {
          const { collectionIcon, collectionDescription, _key } = item;
          return (
            <ParallaxColumn
              index={index}
              key={_key}
              image={collectionIcon}
              description={collectionDescription}
              collection={item}
              isLast={isLast}
              scrollValue={scrollValue}
            />
          );
        }
        if (isSanityPackaging(item)) {
          const { packagingIcon, packagingDescription, _key } = item;
          return (
            <ParallaxColumn
              index={index}
              key={_key}
              image={packagingIcon}
              description={packagingDescription}
              isLast={isLast}
              scrollValue={scrollValue}
            />
          );
        }
        return null;
      })}
    </ParallaxWrapper>
  );
}

export default ParallaxColumns;
