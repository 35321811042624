import firebase from "gatsby-plugin-firebase";
import { Dispatch, SetStateAction } from "react";
import { Buffer } from "buffer";

import { useAllProductQuery } from "@api";
import {
  Maybe,
  SanityAttributeBlock,
  SanityCollection,
  SanityProduct,
  SanityRegionalPricing,
} from "@graphql-types";
import {
  AdCopyToSave,
  isSanityAboutPage,
  isSanityCareersPage,
  isSanityCollection,
  isSanityFaqsPage,
  isSanityHomePage,
  isSanityPoliciesPage,
  isSanityProduct,
  isSanityPromotionalProducts,
  isSanityResourcesPage,
  isSanityTestimonialsPage,
} from "./types";
import { AdCopy, AdElement, Order } from "@state/types";
import { IS_NZ, quillFonts } from "./constants";
import { toast } from "react-toastify";

export const isBrowser = () => typeof window !== "undefined";

export function arraysEquality(array1: any[], array2: any[]) {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => JSON.stringify(value) === JSON.stringify(array2[index]))
  );
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

export function splitArrayIntoChunks(array: any[], split: number) {
  const chunks = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / split);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return chunks;
}

export function splitArrayByCategory(
  products: SanityProduct[],
  collectionSlugs: (Maybe<string> | undefined)[],
) {
  const group = collectionSlugs.map(slug => {
    const productsInCollection = products.filter(
      product => product.categories && product.categories[0]?.collectionRef?.slug?.current === slug,
    );
    return productsInCollection;
  });

  return group;
}

const pages = require("./pagesPaths");

export function formatInternalLink(link: any) {
  if (isSanityCollection(link)) {
    return pages.collections;
  }
  if (isSanityProduct(link)) {
    return pages.products;
  }
  if (isSanityHomePage(link)) {
    return pages.home;
  }
  if (isSanityAboutPage(link)) {
    return pages.about;
  }
  if (isSanityCareersPage(link)) {
    return pages.careers;
  }

  if (isSanityFaqsPage(link)) {
    return pages.faqs;
  }
  if (isSanityPoliciesPage(link)) {
    return pages.policies;
  }
  if (isSanityPromotionalProducts(link)) {
    return pages.promotionalProducts;
  }
  if (isSanityResourcesPage(link)) {
    return pages.resources;
  }
  if (isSanityTestimonialsPage(link)) {
    return pages.testimonials;
  }
  return "/";
}

export function calculateGridItemBorders(index: number, lastItem: number, arrayLength: number) {
  const lastRowHasThree = (lastItem - 1) % 3 === 2 && index > arrayLength - 4;
  const lastRowHasTwo = (lastItem - 1) % 3 === 1 && index > arrayLength - 3;
  const lastRowHasOne = (lastItem - 1) % 3 === 0 && index === arrayLength - 1;
  const noBorderLeft = index === 0 || ((index + 1) % 3 === 1 && index + 1 > 3);
  const noBorderBottom = lastRowHasOne || lastRowHasTwo || lastRowHasThree;

  return { noBorderBottom, noBorderLeft };
}

export const filterProductByRegion = (product: SanityProduct) => {
  return product.regions?.some(
    productRegion => productRegion?.region?.countryCode === process.env.GATSBY_REGION,
  );
};

export function filterProducts(products: SanityProduct[], filters?: Maybe<string[]> | undefined) {
  const filteredProducts = products.filter(product => {
    if (product == null) return;
    const categoriesList = product.categories?.map(category => category?.title);

    if (categoriesList && filters && filters.length > 0) {
      return (
        categoriesList.some(item => item && filters.includes(item)) &&
        filterProductByRegion(product)
      );
    }
    return filterProductByRegion(product);
  });
  return filteredProducts;
}

export function checkCategoryProductLength(
  categoryName: Maybe<string> | undefined,
  products: SanityProduct[] | undefined,
  collectionName?: Maybe<string> | undefined,
) {
  if (products && categoryName && collectionName) {
    const sortedProducts = products.filter(product => {
      return (
        product?.categories?.some(
          productCategory => productCategory && categoryName === productCategory.title,
        ) &&
        product?.categories?.some(
          productCategory =>
            productCategory && collectionName === productCategory?.collectionRef?.title,
        )
      );
    });
    return sortedProducts;
  }
  return;
}

export function flatten(arr: any) {
  return arr.reduce(function (flat: any, toFlatten: any) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

export function getUniqueListBy(arr: any, key: string) {
  return [...new Map(arr.map((item: any) => [item[key], item])).values()];
}

export function sortProductsByCategoryOrder(
  collection: SanityCollection,
  products: SanityProduct[],
) {
  if (collection == null || products == null) return null;

  const categoriesArray = collection.collectionCategories?.map(category => {
    if (category == null) return;
    if (category.title == null) return;

    return filterProducts(products, [category?.title]);
  });

  const flattenedProducts = flatten(categoriesArray);
  const uniqueProducts = getUniqueListBy(flattenedProducts, "id");

  return uniqueProducts as SanityProduct[];
}

//cache storage for offline functionality
export const saveToCache = async (data: any, cacheName: Maybe<string> | undefined) => {
  if (cacheName == null) return;
  const jsonResponse = new Response(JSON.stringify(data), {
    headers: {
      "content-type": "application/json",
    },
  });

  const cached = await caches.open(cacheName);
  return cached
    .put(`/${cacheName}.json`, jsonResponse)
    .then(response => {
      console.log("saved to cache");
      return response;
    })
    .catch(error => {
      console.log("failed save to cache");
      return error;
    });
};

export const getFileFromCache = async (cacheName: string) => {
  const cacheStorage = await caches.open(cacheName);
  const cachedResponse = await cacheStorage.match(`/${cacheName}.json`);

  if (!cachedResponse || !cachedResponse.ok) {
    return false;
  }

  return await cachedResponse.json();
};

export async function deleteFileFromCache(cacheName: string | undefined) {
  if (cacheName == null) return;
  await caches.open(cacheName).then(cache => {
    cache.delete(`/${cacheName}.json`);
  });
}

export function checkIsMicroSite() {
  if (process.env.GATSBY_IS_MICROSITE == null) return true;
  if (process.env.GATSBY_IS_MICROSITE === "false") return false;
  return true;
}

export function sortProductAttributes(attributes: SanityAttributeBlock[]) {
  let nonPackagingAttributes = attributes.filter(attribute => {
    if (attribute == null) return null;
    return attribute.attribute?.title !== "Packaging";
  });
  const packagingAttributes = attributes.filter(attribute => {
    if (attribute == null) return null;
    return attribute.attribute?.title === "Packaging";
  });

  packagingAttributes[0] && nonPackagingAttributes.push(packagingAttributes[0]);

  return nonPackagingAttributes;
}

export function checkCollectionProductLength(collection: SanityCollection) {
  if (collection == null) return null;
  const products = useAllProductQuery(collection.title);

  const regionProducts = products.filter(product => {
    if (product == null) return null;
    return filterProductByRegion(product);
  });

  return regionProducts.length;
}

// map firestore docs
export const firestoreDocsArray = (
  docRef: firebase.firestore.Query<firebase.firestore.DocumentData>,
): Promise<{ id: string; [key: string]: string }[]> => {
  return docRef.get().then(snapshot =>
    snapshot.docs.map(x => {
      const id = x.id;
      const data = x.data();

      return { id, ...data };
    }),
  );
};

export async function fetchDocsFromFirestore(
  docRef: firebase.firestore.Query<firebase.firestore.DocumentData>,
) {
  const docsArray = await firestoreDocsArray(docRef);
  // filter objects in current region
  return docsArray.filter(doc => doc?.country === process.env.GATSBY_REGION);
}

export function setDocToState(
  docRef: firebase.firestore.Query<firebase.firestore.DocumentData>,
  setState: Dispatch<SetStateAction<any[] | undefined>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) {
  fetchDocsFromFirestore(docRef)
    .then(docs => {
      if (docs == null) return;
      setLoading(false);
      setState(docs);
    })
    .catch((error: any) => {
      setLoading(false);
      console.log("error setting firestore doc to state", error);
    });
}

export function getRegionPricing(regions: Maybe<Maybe<SanityRegionalPricing>[]> | undefined) {
  if (regions == null) return null;
  const currentRegion = regions.find(
    region => region?.region?.countryCode === process.env.GATSBY_REGION,
  );
  return currentRegion?.pricing;
}

export async function formatBase64(imageString: string) {
  if (imageString.includes("base64")) {
    return Buffer.from(imageString.replace(/^data:image\/png;base64,/, ""), "base64");
  }

  try {
    const response = await fetch(imageString);
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    return Buffer.from(arrayBuffer);
  } catch (error) {
    console.error("Error formatting image from url:", error);
    return undefined;
  }
}

export function filterRemovedAdElements(
  elements: AdElement[] | undefined,
): AdElement[] | undefined {
  if (elements == null) return undefined;
  return elements.filter(element => !element.toRemove);
}

export function validateEmail(email: string) {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
}

export function calculateOverallShippingCost(
  shippingCost: number | undefined,
  overallCalendarPrice: number,
) {
  const costAtFourPercent = IS_NZ ? 0 : overallCalendarPrice * 0.04;
  return Number(shippingCost ?? costAtFourPercent);
}

export function calculateOverallMixAndMatchCost(mixAndMatch: Partial<Order>[] | undefined) {
  if (mixAndMatch == null || mixAndMatch.length === 0) return 0;
  const mixAndMatchCost = mixAndMatch.reduce(
    (acc, order) => (order.price && order.quantity ? acc + order.price * order.quantity : acc + 0),
    0,
  );

  return mixAndMatchCost;
}

export function calculateTotalOrderPrice(order: Partial<Order>) {
  const {
    quantity: calendarQuantity,
    price: calendarPricePerUnit,
    packaging: packages,
    shippingCost,
    mixAndMatch,
  } = order;

  if (calendarPricePerUnit && calendarQuantity) {
    const overallCalendarPrice = calendarPricePerUnit * calendarQuantity;
    const overallShippingCost = calculateOverallShippingCost(shippingCost, overallCalendarPrice);
    const overallMixAndMatchCost = calculateOverallMixAndMatchCost(mixAndMatch);

    const overallPackagingPrice = packages
      ? packages.reduce((acc, packaging) => acc + packaging.price! * packaging.quantity!, 0)
      : 0;
    const totalPrice =
      overallCalendarPrice + overallShippingCost + overallPackagingPrice + overallMixAndMatchCost;

    return {
      totalPrice,
      overallCalendarPrice,
      overallPackagingPrice,
      overallShippingCost,
      overallMixAndMatchCost,
    };
  }

  return null;
}

export function numberWithCommas(numString: string) {
  return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function orderPriceString(totalPrice: number | undefined) {
  if (!totalPrice) return "TBD";
  return totalPrice > 0 ? `$${numberWithCommas(totalPrice.toFixed(2))}` : "TBD";
}

export const mixAndMatchGroq = (projection: string) => `*[_type=="product" && mixMatch==true && 
advertSize.height == $advertHeight && advertSize.width == $advertWidth && 
productSize.height == $productHeight && productSize.width == $productWidth && 
$countryCode in regions[].region->countryCode]${projection}`;

export const createQuillFontStyle = () => {
  const qlPicker = quillFonts.map(fontObj => {
    const { font, name, fontId } = fontObj;

    return `.ql-picker-label[data-value="${fontId}"]::before {
      content: "${name}" !important;
      font-family: "${font}";
    }`;
  });

  const qlPickerOptions = quillFonts.map(fontObj => {
    const { font, name, fontId } = fontObj;

    return `.ql-font .ql-picker-options span[data-value="${fontId}"]::before {
      content: "${name}" !important;
      font-family: "${font}";
      font-size: 16px;
    }`;
  });

  const fontFams = quillFonts.map(fontObj => {
    const { font, fontId } = fontObj;

    return `.ql-font-${fontId} {
      font-family: "${font}";
    }`;
  });

  return `.ql-font.ql-picker {
    ${qlPicker.join("\n")}
  }
  ${qlPickerOptions.join("\n")}
  ${fontFams.join("\n")}
  `;
};

export function replacePlaceholdersInValues(arr: any, replacements: any) {
  const formatted = JSON.parse(JSON.stringify(arr)).map((obj: any) => {
    if (obj.value && typeof obj.value === "string") {
      for (let key in replacements) {
        obj.value = obj.value.replace(new RegExp(key, "g"), replacements[key]);
        console.log(obj.value);
      }
    }
    console.log({ obj });

    return obj;
  });

  return formatted;
}

export function updateClipboard(newClip: string) {
  navigator.clipboard.writeText(newClip).then(
    () => {
      /* clipboard successfully set */
      toast.success("Copied to clipboard");
    },
    () => {
      /* clipboard write failed */
    },
  );
}

export function getCookie(cookieName: string) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null; // Return null if the cookie is not found
}

export function setCookie(cookieName: string, cookieValue: string, expirationDays: number) {
  const d = new Date();
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

/**
 * Debounces a function by the specified wait time.
 * @param callback - The function to debounce
 * @param wait - The time to wait before calling the function
 */
export function debounce(
  callback: (...args: any[]) => void,
  wait: number,
): (...args: any[]) => void {
  let timeoutId: number | undefined;

  return (...args: any[]): void => {
    if (!isBrowser) return;

    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

export function getAdCopyToSave({
  imageElements,
  textElements,
  shapeElements,
  overrideBackgroundColour,
  backgroundUrl,
  bgOpacity,
  order,
  notes,
  copy,
}: AdCopyToSave) {
  const adCopyToSave = {
    adCopyElements: {
      imageElements: filterRemovedAdElements(imageElements) ?? "",
      textElements: filterRemovedAdElements(textElements) ?? "",
      shapeElements: filterRemovedAdElements(shapeElements) ?? "",
      backgroundColour: overrideBackgroundColour ?? "",
      backgroundUrl: backgroundUrl ?? "",
      backgroundTransparency: bgOpacity ?? 100,
    },
    calendarId: order?.product?.productId,
    orderId: order?.id,
    notes,
    copy,
  };

  return adCopyToSave as AdCopy;
}

/*
 * Save Template Thumbnail to firebase storage
 */
export async function saveBase64ToStorage(imageString?: string, storagePath?: string) {
  if (!imageString || !storagePath) return;
  if (imageString.includes("http")) return imageString;
  const storage = firebase.storage();

  const storageRef = storage.ref(storagePath);
  const uploadImage = await storageRef.putString(imageString, "data_url");
  const downloadURL = await uploadImage.ref.getDownloadURL();

  return downloadURL as string;
}
