import { CSSProperties, Dispatch, SetStateAction } from "react";
import { assets, buttonStyle, colors, colorsRGB, fontWeights } from "./constants";

import {
  SanityCollection,
  SanityColumnContent,
  SanityFeaturedCollections,
  SanityImage,
  SanityImageLink,
  SanityImageMenu,
  SanityInformationColumns,
  SanityLink,
  SanityNestedLink,
  SanityProduct,
  SanityRange,
  SanityVideo,
  SanityThemeSelector,
  SanityTestimonialsSlider,
  SanityListBlock,
  SanityHome,
  SanityAboutPage,
  SanityCareersPage,
  SanityContactPage,
  SanityFaqsPage,
  SanityPoliciesPage,
  SanityPromotionalProducts,
  SanityResourcesPage,
  SanityTestimonialsPage,
  Maybe,
  SanityPackaging,
  SanityHero,
} from "@graphql-types";
import { AdElement, Order } from "@state/types";

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export type SelectedCalendar = {
  productId?: Maybe<string> | undefined;
  productName?: Maybe<string> | undefined;
};

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export interface ScreenWidth {
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isLaptopWidth: boolean;
}

export interface CanvasSize {
  width: number;
  height: number;
}

export interface SelectOption {
  id: string;
  title: string;
}

export interface MailOptions {
  from: string;
  to: string;
  subject: string;
  html: string;
}

export interface MixAndMatchParams {
  countryCode: string | undefined;
  advertHeight: Maybe<number> | undefined;
  advertWidth: Maybe<number> | undefined;
  productHeight: Maybe<number> | undefined;
  productWidth: Maybe<number> | undefined;
}

export type ShapeName = "circle" | "square";
export interface ShapeProps {
  selected?: boolean;
  background?: string;
  shapeName: ShapeName | string;
  border?: CSSProperties["border"];
  borderWidth?: number;
}

export interface AdCopyToSave {
  imageElements: AdElement[] | undefined;
  textElements: AdElement[] | undefined;
  shapeElements: AdElement[] | undefined;
  backgroundUrl: string | undefined;
  bgOpacity: number | undefined;
  notes: string;
  copy: string;
  overrideBackgroundColour: string;
  order: Order | undefined;
}

export function isSanityCollection(data: any): data is SanityCollection {
  return data._type === "collection";
}

export function isSanityPackaging(data: any): data is SanityPackaging {
  return data._type === "packaging";
}

export function isSanityProduct(data: any): data is SanityProduct {
  return data._type === "product";
}

export function isSanityRange(data: any): data is SanityRange {
  return data._type === "range";
}

export function isSanityImageLink(data: any): data is SanityImageLink {
  return data._type === "imageLink";
}
export function isSanityLink(data: any): data is SanityLink {
  return data._type === "link";
}
export function isSanityNestedLink(data: any): data is SanityNestedLink {
  return data._type === "nestedLink";
}

export function isSanityImageMenu(data: any): data is SanityImageMenu {
  return data._type === "imageMenu";
}

export function isSanityColumnContent(data: any): data is SanityColumnContent {
  return data._type === "columnContent";
}

export function isSanityFeaturedCollections(data: any): data is SanityFeaturedCollections {
  return data._type === "featuredCollections";
}
export function isSanityInformationColumns(data: any): data is SanityInformationColumns {
  return data._type === "informationColumns";
}

export function isSanityVideo(data: any): data is SanityVideo {
  return data._type === "video";
}

export function isSanityThemeSelector(data: any): data is SanityThemeSelector {
  return data._type === "themeSelector";
}

export function isSanityTestimonialsSlider(data: any): data is SanityTestimonialsSlider {
  return data._type === "testimonialsSlider";
}

export function isSanityListBlock(data: any): data is SanityListBlock {
  return data._type === "listBlock";
}

export function isSanityHero(data: any): data is SanityHero {
  return data._type === "hero";
}

export function isSanityImageWithMeta(data: any): data is SanityImage {
  return data._type == "imageWithMeta";
}

// STATIC PAGES

export function isSanityHomePage(data: any): data is SanityHome {
  return data._type === "home";
}
export function isSanityAboutPage(data: any): data is SanityAboutPage {
  return data._type === "aboutPage";
}
export function isSanityCareersPage(data: any): data is SanityCareersPage {
  return data._type === "careersPage";
}
export function isSanityContactPage(data: any): data is SanityContactPage {
  return data._type === "contactPage";
}
export function isSanityFaqsPage(data: any): data is SanityFaqsPage {
  return data._type === "faqsPage";
}
export function isSanityPoliciesPage(data: any): data is SanityPoliciesPage {
  return data._type === "policiesPage";
}
export function isSanityPromotionalProducts(data: any): data is SanityPromotionalProducts {
  return data._type === "promotionalProducts";
}
export function isSanityResourcesPage(data: any): data is SanityResourcesPage {
  return data._type === "resourcesPage";
}
export function isSanityTestimonialsPage(data: any): data is SanityTestimonialsPage {
  return data._type === "testimonialsPage";
}
